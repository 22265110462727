import React from "react";

const Faq = () => {
  const faqs = [
    {
      question: "How many images are required to make a 3d model?",
      answer: "3-4 random images are enough to get started.",
    },
    {
      question: "What if i have only single image?",
      answer: "Dont worry it will work.",
    },
    {
      question: "Can you make 3d model of anything?",
      answer: "Yes we can make 3d model of anything you can think of.",
    },
    {
      question: "How much time will it take to make a 3d model?",
      answer: "Generally it takes 12-15 working days.",
    },
    {
      question: "How many revisions are included?",
      answer: "You'll receive three revisions as part of the quoted price.",
    },
    {
      question: "Which file formats do you provide?",
      answer: "We provide STL, OBJ, FBX, and ZBrush files for your 3D models.",
    }
  ];
  return (
    <div className="pb-10 pt-10 lets-do-it-box rounded-lg mx-4 lg:w-1/2 sm:w-full">
      <div className="text-xl font-bold lg:text-4xl text-[#8C98A4]">FAQ's</div>
      {faqs.map((item) => {
        return (
          <div>
            <div className="collapse collapse-arrow">
              <input type="checkbox" className="peer " />
              <div className="collapse-title text-sm lg:text-xl bg-transparent text-white peer-checked:bg-transparent peer-checked:text-white">
                {item.question}
              </div>
              <div className="collapse-content bg-transparent text-primary-content peer-checked:bg-transparent peer-checked:text-secondary-content">
                <p className="font-semibold text-sm lg:text-xl">
                  {item.answer}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Faq;
