import React, { useRef, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow, } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import i1 from "../../../../../assets/images/reviewcarousel/1.webp"
import i2 from "../../../../../assets/images/reviewcarousel/2.webp"
import i3 from "../../../../../assets/images/reviewcarousel/3.webp"
import i4 from "../../../../../assets/images/reviewcarousel/4.webp"
import i5 from "../../../../../assets/images/reviewcarousel/5.webp"
import i6 from "../../../../../assets/images/reviewcarousel/6.webp"
import i7 from "../../../../../assets/images/reviewcarousel/7.webp"
import i8 from "../../../../../assets/images/reviewcarousel/8.webp"
import i9 from "../../../../../assets/images/reviewcarousel/9.webp"
import i10 from "../../../../../assets/images/reviewcarousel/10.webp"
import i11 from "../../../../../assets/images/reviewcarousel/11.webp"
import i12 from "../../../../../assets/images/reviewcarousel/12.webp"
import i13 from "../../../../../assets/images/reviewcarousel/13.png"
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import "../../../../../sass/files/reviewslider.scss";

// import required modules
//transform: translate3d(-552px, 0px, 0px);

export default function App() {
  return (
    <>
   <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 150,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        }}
        initialSlide={4}
        className="mySwiper"
        spaceBetween={50}
        navigation
        modules={[EffectCoverflow, Navigation, Pagination, Scrollbar, A11y]}
      >
        <SwiperSlide>
        <img src={i1} className="h-full" />
        </SwiperSlide>
        <SwiperSlide>
        <img src={i2} className="h-full" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i3} className="h-full"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i4}  className="h-full" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i5} className="h-full" />
        </SwiperSlide>
  
        <SwiperSlide>
          <img src={i6}  className="h-full"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i7}  className="h-full" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i8}  className="h-full"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i9}  className="h-full"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i10}  className="h-full"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i11}  className="h-full"  />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i12}  className="h-full" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={i13}  className="h-full" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
